<template>
    <div style="height:90vh;line-height:90vh;text-align:center;font-size:1.6rem;">正在載入付款頁面...</div>
</template>

<script>
    export default {
        name: "",
        components:{},
        data() {
            return {}
        },
        created(){},
        mounted(){


            let no = this.$route.query.no;
            if( no ){
                this.api.get('orders/lvpay',{no:no}).then( res => {
                    location.href = "https://ht.rosa9981.com/api/orders/lvpay?no="+no;
                    //location.href = 'https://payment.ecpay.com.tw/SP/SPCheckOut?MerchantID=' + res.data.mid + '&SPToken=' + res.data.token + '&PaymentType=CREDIT';
                })
            }
        },
        methods: {},
        watch: {},
    }
</script>

<style scoped></style>